import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const DEFAULT_FILTERS = {
  bedrooms: null,
  bdexact: null,
  bathrooms: null,
  baexact: null,
  minPrice: '',
  maxPrice: '',
  propertyCategory: null,
  roomForRent: null,
  verifiedFlag: false,
  pets: null,
  petPolicy: false,
  sortOrder: { value: 'random', order: 'lh' },
};

export const useStoreFilters = create(
  persist(
    (set, get) => ({
      filters: {
        ...DEFAULT_FILTERS,
        location: '',
        locationKey: '',
        page: 0,
        ssrKey: null,
        showWithinBoundary: null,
      },
      locationBBox: null,
      setLocationBBox: (locationBBox) => {
        set((state) => ({ filters: state.filters, locationBBox }));
      },
      setLocation: (location) => {
        set((state) => ({
          filters: { ...state.filters, location },
          locationBBox: state.locationBBox,
        }));
      },
      setPage: (page) => {
        set((state) => ({
          filters: { ...state.filters, page },
          locationBBox: state.locationBBox,
        }));
      },
      setBeds: (bedrooms) => {
        set((state) => ({
          filters: { ...state.filters, bedrooms },
          locationBBox: state.locationBBox,
        }));
      },
      setBdExact: (bdexact) => {
        set((state) => ({
          filters: { ...state.filters, bdexact },
          locationBBox: state.locationBBox,
        }));
      },
      setBaths: (bathrooms) => {
        set((state) => ({
          filters: { ...state.filters, bathrooms },
          locationBBox: state.locationBBox,
        }));
      },
      setBaExact: (baexact) => {
        set((state) => ({
          filters: { ...state.filters, baexact },
          locationBBox: state.locationBBox,
        }));
      },
      setMinPrice: (minPrice) => {
        set((state) => ({
          filters: { ...state.filters, minPrice },
          locationBBox: state.locationBBox,
        }));
      },
      setMaxPrice: (maxPrice) => {
        set((state) => ({
          filters: { ...state.filters, maxPrice },
          locationBBox: state.locationBBox,
        }));
      },
      setPropertyCategory: (propertyCategory) => {
        set((state) => ({
          filters: { ...state.filters, propertyCategory },
          locationBBox: state.locationBBox,
        }));
      },
      setRoomForRent: (roomForRent) => {
        set((state) => {
          if (roomForRent !== state?.filters?.roomForRent) {
            return {
              filters: { ...state.filters, roomForRent },
              locationBBox: state.locationBBox,
            };
          }

          return state;
        });
      },
      setVerified: (verifiedFlag) => {
        set((state) => ({
          filters: { ...state.filters, verifiedFlag },
          locationBBox: state.locationBBox,
        }));
      },
      setPets: (pets) => {
        set((state) => ({
          filters: { ...state.filters, pets },
          locationBBox: state.locationBBox,
        }));
      },
      setPetPolicy: (petPolicy) => {
        set((state) => ({
          filters: { ...state.filters, petPolicy },
          locationBBox: state.locationBBox,
        }));
      },
      setSortOrder: (sortOrder) => {
        set((state) => ({
          filters: { ...state.filters, sortOrder },
          locationBBox: state.locationBBox,
        }));
      },
      toggleWithingBoundary: () =>
        set((state) => ({
          filters: {
            ...state.filters,
            showWithinBoundary: !state.filters.showWithinBoundary,
          },
          locationBBox: state.locationBBox,
        })),
      setBoundingBox: ({ bottomLat, leftLong, rightLong, topLat }) =>
        set((state) => ({
          filters: {
            ...state.filters,
            bottomLat,
            leftLong,
            rightLong,
            topLat,
            lat: (bottomLat + topLat) / 2,
            long: (leftLong + rightLong) / 2,
          },
          locationBBox: state.locationBBox,
        })),
      resetBoundingBox: () =>
        set((state) => {
          const newState = {
            filters: { ...state.filters },
            locationBBox: state.locationBBox,
          };
          delete newState.filters.topLat;
          delete newState.filters.bottomLat;
          delete newState.filters.leftLong;
          delete newState.filters.rightLong;
          delete newState.filters.lat;
          delete newState.filters.long;

          return newState;
        }),
      syncWithSsr: (ssrFilters) =>
        set((state) => ({
          filters: { ...DEFAULT_FILTERS, ...ssrFilters },
          locationBBox: state.locationBBox,
        })),
      setFilters: (filters) =>
        set((state) => ({
          filters: { ...state.filters, ...filters },
          locationBBox: state.locationBBox,
        })),
      getFilters: () => get().filters,
    }),
    {
      name: 'filters-storage',
    },
  ),
);

export default useStoreFilters;
