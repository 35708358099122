import { formatPrice } from 'components/common/ResultsPanel/utils';
import React from 'react';

export default function PriceIndicator({ input, minPrice, maxPrice }) {
  if (minPrice === '' && maxPrice === '' && input) {
    return (
      <>
        <div className="sm:hidden">Price Range</div>
        <div className="hidden sm:flex">Min / Max</div>
      </>
    );
  }

  return (
    <div>
      {minPrice ? (
        <span className="lowercase">{`$${formatPrice(minPrice)}`}</span>
      ) : (
        'Min'
      )}
      &nbsp;{minPrice || maxPrice ? '-' : '/'}&nbsp;
      {maxPrice ? (
        <span className="lowercase">{formatPrice(maxPrice)}</span>
      ) : (
        'Max'
      )}
    </div>
  );
}
