export function propertyIsNew(dateString) {
  if (typeof dateString === 'undefined' || dateString === null) return false;

  const iosFriendlyDateString = dateString.toString().replace(/-/g, '/');

  const now = new Date();
  const date = new Date(Date.parse(iosFriendlyDateString));
  const twoDaysInMilliseconds = 24 * 2 * 60 * 60 * 1000;

  if (now - date < twoDaysInMilliseconds) return true;

  return false;
}

export function drawScrollableInfoWindow(mainData, properties) {
  let infoWindow = `<div class='slick-counter'><span>1</span> of ${properties.length} units</div>`;
  infoWindow += `<div id='cluster-map-properties-${properties[0].property_id$}'>`;
  properties.forEach((property, index) => {
    if (index === 0) {
      infoWindow += drawInfoWindow(formatPropertyResponse(mainData));
    } else {
      infoWindow += drawSmallInfoWindowOther(property);
    }
  });
  infoWindow += '</div>';
  return infoWindow;
}

export function drawSmallInfoWindowOther(property) {
  return `<div id="map-card-${property.property_id}" class="map-card" data-id="${property.property_id}"></div>`;
}

export function drawInfoWindow(property) {
  let image = 'img/dwellsy/logos/logo-white.svg';
  if (property.image) {
    image = property.image;
  }

  let address = '';
  if (property.address) {
    address = property.address;
  }

  let addressUrl = '';
  if (property.address_url) {
    addressUrl = property.address_url;
  }

  let area = '--';
  if (property.area) {
    area = property.area;
  }

  let bedroom = '--';
  if (property.bedroom) {
    bedroom = property.bedroom;
  }

  let bathroom = '--';
  if (property.bathroom) {
    bathroom = property.bathroom;
  }

  let price = '--';
  if (property.price) {
    price = property.price;
  }
  let dwellsyScore = '';
  if (property.dwellsy_score) {
    dwellsyScore = property.dwellsy_score;
  }

  let isVerified = '';
  if (property.is_verified) {
    isVerified =
      '<img src="/static/img/icon-dwellsy-verified.svg" alt="Dwellsy Verified!" title="Dwellsy Verified!"> <span class="verified-label">Verified</span>';
  } else {
    isVerified =
      '<img src="/static/img/icon-unverified.svg" alt="Unverified" title="Unverified"> <span class="verified-label">Unverified</span>';
  }

  let date = '';
  if (property.date_available) {
    date = `Available on ${property.date_available}`;
  } else {
    date = 'Available now';
  }

  let toggleClass;
  let favState;
  if (property.favorite) {
    toggleClass = 'on';
    favState = 'Remove';
  } else {
    toggleClass = '';
    favState = 'Save';
  }

  const ibContent = `
      <div class="map-card">
       <div class="property-box2 grid" id="${property.id}" data-prop-count="${
    property.prop_count
  }">
          <div class="property-thumbnail">
            <a href="/property-details/${
              property.id
            }/${addressUrl}/" class="property-img" style="background-image:url('${image}')"  target="_blank" >
              <div class="d-flex justify-content-between">
                <span class="mr-3 info card-verified" data-toggle="modal" data-target="#dwellsyVerified">${isVerified}</span>
                <label class="card-availibity">${date}</label>
              </div>
            </a>
           </div>
           <div class="detail p-2">
              <div class="d-flex justify-content-between">
                <div class="prop-stats">
                  <span class="ppm-title">$${formatNumber(
                    price,
                  )} <span class="small">/mo</span></span>
                  <span class="stat">${bedroom}</span><span class="small">Beds</span>
                  <span class="slash">/</span><span class="stat">${bathroom}</span><span class="small">Bath</span>
                  <span class="slash">/</span><span class="stat">${formatNumber(
                    area,
                  )}</span>
                  <span class="small">Sqft</span>
                </div>
                <div>
                <div class="dscore-component-wrapper mt-1" data-toggle="modal" data-target="#dscore">
                  <p class="d-title  d-none">Dwellsy Match</p>
                  <div class="dscore-wrapper"><i class="si si-dscore"></i>${dwellsyScore}%</div></div>
                </div>
              </div>
              <h3 class="title"><a href="/property-details/${
                property.id
              }/${addressUrl}/"  target="_blank" >${address}<div class="small">${
    property.addressCity
  }, ${property.address_state}</div></a></h3>
              <div class="d-flex justify-content-between">
                <div class="contact-button">
                  <a class="btn btn-contact-sm"
                    onClick="contactLinkClickHandler('${
                      property.id
                    }', '${address}, ${property.addressCity}, ${
    property.address_state
  }', 'Search Page Map')"
                    data-attr="${property.id}"
                    data-address="${address}, ${property.addressCity}, ${
    property.address_state
  }">
                      Contact
                  </a>
                </div>
                <div class="card-buttons d-flex">
                  <div class="dropdown-container">
                    <a onClick="favorite('${
                      property.id
                    }')" class="btn btn-favorite ${toggleClass}" data-image="${image}" data-attr="${
    property.id
  }"><span class="fav-label">${favState}</span></a>
                  </div>
                  <a data-property-id="${property.id}" data-attr="${
    property.id
  }" class="btn-hide d-none ${toggleClass}"><label class="card-label"></label><i data-attr="${
    property.id
  }" class="si si-hide hide-toggle "></i></a>
                </div>
               </div>
           </div>
        </div>
      </div>
    `;
  return ibContent;
}

export function getElementOffset(element) {
  if (!element) return { top: 0, left: 0 };

  const de = document.documentElement;
  const box = element.getBoundingClientRect();
  const top = box.top + window.pageYOffset - de.clientTop;
  const left = box.left + window.pageXOffset - de.clientLeft;
  return { top, left };
}

export function formatPropertyResponse(prop) {
  if (prop.listings.length === 0) return null;

  let addressUrl = `${prop.address_1}-`;
  let address = prop.address_1;
  if (prop.address_2) {
    addressUrl += `${prop.address_2}-`;
    address += `, ${prop.address_2}`;
  }

  addressUrl += `${prop.address_city}-${prop.address_state}`;

  const { address_city: addressCity, addressState, media } = prop;

  addressUrl = addressUrl.replace(/\s/g, '-');

  let image;
  if (media.length) {
    image = prop.media[0]?.media_url;
  } else {
    image = '/static/img/no-image.png'; // -- hard coding a default image
  }
  let formattedDate = null;
  if (prop.listings[0].date_available) {
    const jsDate = new Date(prop.listings[0].date_available);
    const mo = jsDate.getMonth() + 1;
    const da = jsDate.getDate();
    const yr = jsDate.getFullYear();
    formattedDate = `${mo}/${da}/${yr}`;
  }

  let listing = [];
  listing = {
    id: parseInt(prop.id, 10),
    // prop_count: key,
    title: address,
    is_featured: false,
    is_excluded: prop.excluded_flag,
    listing_for: 'Rent',
    latitude: parseFloat(prop.latitude),
    longitude: parseFloat(prop.longitude),
    address,
    address_city: addressCity,
    address_state: addressState,
    address_url: addressUrl,
    area: parseFloat(prop.square_feet),
    bedroom: parseFloat(prop.bedrooms),
    bathroom: parseFloat(prop.full_baths),
    price: parseFloat(prop.listings[0].listing_amount),
    image,
    dwellsy_score: prop.dwellsy_score,
    is_verified: parseFloat(prop.verified_flag),
    favorite: prop.saved_flag,
    date_available: formattedDate,
  };
  return listing;
}

export function formatNumber(num) {
  const formatted = num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  return formatted.replace('.00', '');
}

export function formatPrice(price) {
  let formattedPrice = parseInt(price, 10);
  if (formattedPrice >= 1000000) {
    formattedPrice = `${(formattedPrice / 1000).toFixed(1)}M`;
  }
  if (formattedPrice >= 1000) {
    formattedPrice = `${(formattedPrice / 1000).toFixed(1)}K`;
  }
  return formattedPrice;
}
