import PriceIndicator from 'components/Search/Filters/SearchModal/PriceAccordion/PriceIndicator';
import BedBathIndicator from 'components/Search/Filters/SearchModal/RoomsAccordion/BedBathIndicator';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import IconFilterOff from 'public/images/filter-off.svg';
import IconFilterOn from 'public/images/filter-on.svg';
import React, { useEffect, useState } from 'react';
import { useStoreFilters } from 'store/store-filters';

const SearchModal = dynamic(() =>
  import('components/Search/Filters/SearchModal/SearchModal'),
);

export default function LocationSearchInput({
  resetOverflow = true,
  truncateSize,
}) {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState('');
  const filters = useStoreFilters((state) => state.filters) ?? '';
  const { location } = filters;
  const moreApplied = !(
    filters?.propertyCategory === null &&
    filters?.roomForRent === null &&
    filters?.verifiedFlag === false &&
    filters?.pets === null &&
    filters?.petPolicy === false
  );

  useEffect(() => {
    if (resetOverflow) {
      document.body.style.overflow = open ? 'hidden' : 'unset';
    }
    const handleCommandK = (event) => {
      if (event.metaKey)
        switch (event.keyCode) {
          case 75:
            setOpen(!open);
            break;
          default:
            break;
        }
    };
    document.addEventListener('keydown', handleCommandK);
    return () => {
      if (resetOverflow) {
        document.body.style.overflow = 'unset';
      }
      document.removeEventListener('keydown', handleCommandK);
    };
  }, [open, resetOverflow]);

  useEffect(() => {
    setText(location?.replace(', United States', ''));
  }, [location]);

  return (
    <>
      <button
        id="location-search-input"
        type="button"
        tabIndex="0"
        aria-labelledby="location-search-label1 location-search-label2"
        className={`flex items-center text-start whitespace-nowrap w-full pl-6 mr-2 pr-2 h-[54px] rounded-full bg-white shadow-[0_10px_15px_-10px_rgba(0,0,0,0.1)] border border-solid border-gray-200 
        `}
        onClick={() => {
          setOpen(true);
        }}
      >
        {text ? (
          <div className="flex flex-row w-full">
            <div className="flex flex-col sm:flex-row m-auto w-full">
              <div
                className={`${truncateSize} text-sm md:text-base font-bold truncate sm:my-auto md:w-full`}
              >
                {text}
              </div>

              <div className="flex text-xs text-start text-purple-600 pb-1 items-center">
                <div className="pr-2 sm:px-4 sm:text-base sm:text-black">
                  <div className="text-xs hidden sm:flex">Rooms</div>
                  <BedBathIndicator
                    bedrooms={filters?.bedrooms}
                    bathrooms={filters.bathrooms}
                    input
                  />
                </div>
                <div className="px-2 sm:text-base sm:text-black">
                  <div className="text-xs hidden sm:flex">Price Range</div>
                  <PriceIndicator
                    minPrice={filters?.minPrice}
                    maxPrice={filters?.maxPrice}
                    input
                  />
                </div>
              </div>
            </div>
            <div className="flex items-center">
              {moreApplied ? (
                <Image
                  src={IconFilterOn}
                  width={39}
                  height={44}
                  alt="Filters Applied"
                  className="animate-pulse"
                />
              ) : (
                <Image
                  src={IconFilterOff}
                  width={39}
                  height={44}
                  alt="More Filter"
                />
              )}
            </div>
          </div>
        ) : (
          <>
            <span
              className="w-full text-sm md:text-base rounded-full outline-0 text-gray-400"
              id="location-search-label1"
            >
              Where are you looking to rent?
            </span>
            <span className="text-gray-400" id="location-search-label2">
              &#8984; K
            </span>
          </>
        )}
      </button>

      <SearchModal open={open} setOpen={setOpen} />
    </>
  );
}
